<template>
  <div class="alter">
    <div id="map">
      <div
        id="tool-control"
        @click.stop="changeType($event)"
        v-if="!pathsArr.length"
      >
        <div class="tool-item" id="polygon">{{ drawStatus }}</div>
      </div>
    </div>
    <MyNav></MyNav>
    <div class="edit-map">
      <div class="add-form">
        <el-form :model="form" ref="form" :rules="rules" hide-required-asterisk>
          <el-form-item label="片区区域" :label-width="formLabelWidth">
            <div class="region">
              <p>*请从右侧编辑工具编辑</p>
            </div>
          </el-form-item>

          <el-form-item
            label="填充颜色"
            :label-width="formLabelWidth"
            prop="body_color"
          >
            <div class="slider-box">
              <el-color-picker
                size="small"
                v-model="form.body_color"
                show-alpha
                @active-change="activeChange"
              ></el-color-picker>
            </div>
          </el-form-item>
          <el-form-item
            label="边界颜色"
            :label-width="formLabelWidth"
            prop="border_color"
          >
            <el-color-picker
              size="small"
              v-model="form.border_color"
              @active-change="activeChangeBorder"
            ></el-color-picker>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button class="ok-button" type="primary" @click="editMaps('form')"
          >确 定</el-button
        >
        <el-button class="no-button" @click="hideMaps('form')">取 消</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { getTownInfoApi, postScopeMarkDataApi } from "@/request/api";
import { mapState } from "vuex";
import "ol/ol.css";
import "ol-ext/dist/ol-ext.min.css";
import { Map, View } from "ol";
import OSM from "ol/source/OSM";
import TileLayer from "ol/layer/Tile";
import LayerVector from "ol/layer/Vector";
import SourceVector from "ol/source/Vector";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import { Draw, Modify, defaults } from "ol/interaction";
import Polygon from "ol/geom/Polygon";
import Feature from "ol/Feature";
import hanshan from "@/assets/json/hanshan.json";
import GeoJSON from "ol/format/GeoJSON";

export default {
  components: {
    MyNav: () => import("@/components/MyNav.vue"),
  },

  data() {
    return {
      map: null,
      draw: null,
      vector: null,
      polygonSource: new SourceVector({
        wrapX: false,
      }),
      hanShanSource: new SourceVector({
        features: new GeoJSON().readFeatures(hanshan, {
          dataProjection: "EPSG:4326",
          featureProjection: "EPSG:4326",
        }),
      }),
      // 地块
      plot_id: "",
      // 表单信息
      form: {
        body_color: "rgba(255,255,255,0.4)",
        border_color: "#3E90E5",
        alpha: 30,
      },
      // 表单校验规则
      rules: {
        body_color: [
          { required: true, message: "请选择填充颜色", trigger: "change" },
        ],
        border_color: [
          { required: true, message: "请选择边界颜色", trigger: "change" },
        ],
      },
      formLabelWidth: "120px",
      drawStatus: "开始绘制",
      border_color: "",
      body_color: "",
      // 新增
      pathsArr: [],
      // 中心点
      latNum: 0,
      lngNum: 0,
      scope_id: "",
    };
  },
  watch: {
    "form.border_color": {
      handler(newVal) {
        if (this.vector) {
          this.vector
            .getSource()
            .getFeatures()[0]
            .setStyle(
              new Style({
                fill: new Fill({
                  color: this.form.body_color,
                }),
                stroke: new Stroke({
                  width: 4,
                  color: newVal,
                }),
              })
            );
        }
      },
      deep: true,
      immediate: true,
    },
    "form.body_color": {
      handler(newVal) {
        if (this.vector) {
          this.vector
            .getSource()
            .getFeatures()[0]
            .setStyle(
              new Style({
                fill: new Fill({
                  color: newVal,
                }),
                stroke: new Stroke({
                  width: 4,
                  color: this.form.border_color,
                }),
              })
            );
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      farmInfo: (state) => state.user.farmInfo,
      uploadImgs: (state) => state.app.uploadImgs,
      userInfo: (state) => state.user.userInfo,
    }),
  },
  mounted() {
    this.initMap();
  },
  created() {
    this.scope_id = this.$route.query.id || "";
    this.position = this.farmInfo.position || "";
    this.latNum = this.position.split(",")[1];
    this.lngNum = this.position.split(",")[0];
  },
  methods: {
    rgbaNum(rgba, index) {
      let val = rgba.match(/(\d(\.\d+)?)+/g);
      return val[index];
    },
    activeChangeBorder(e) {
      this.form.border_color = e;
    },
    activeChange(e) {
      this.form.body_color = e;
      let alpha = this.rgbaNum(this.form.body_color, 3);
      this.form.alpha = Math.round(alpha * 100);
    },
    cancelMaskCrop() {
      this.drawPolygon();
    },
    featureStyle(x, y) {
      let style = new Style({
        fill: new Fill({
          color: y,
        }),
        stroke: new Stroke({
          width: 4,
          color: x,
        }),
      });
      return style;
    },
    showPolygon() {
      this.polygonSource.clear();
      let array = this.pathsArr;
      let features = [];
      let coord = array;
      let x = this.form.border_color;
      let y = this.form.body_color;
      let f = new Feature({
        geometry: new Polygon([coord]),
        type: "isPolygon",
      });
      f.setStyle(this.featureStyle(x, y));
      features.push(f);
      this.polygonSource.addFeatures(features);
    },
    drawPolygon() {
      if (this.draw !== null) {
        this.map.removeInteraction(this.draw);
      }
      this.draw = new Draw({
        source: this.polygonSource,
        type: "Polygon",
      });
      this.map.addInteraction(this.draw);
      this.draw.on("drawend", (e) => {
        let feature = e.feature;
        let geom = feature.getGeometry();
        let i2 = geom.getCoordinates()[0];
        this.pathsArr = i2;
        this.map.removeInteraction(this.draw);
        this.startModify();
      });
    },

    startModify() {
      this.modify = new Modify({
        source: this.polygonSource,
        type: "Polygon",
      });
      this.map.addInteraction(this.modify);
      this.modify.on("modifyend", (e) => {
        let modifyGeom = e.features.array_[0].getGeometry();
        let i2 = modifyGeom.getCoordinates()[0];
        this.pathsArr = i2;
      });
    },
    endModify() {
      if (this.modify !== null) {
        this.map.removeInteraction(this.modify);
      }
    },

    initMap() {
      this.vector = new LayerVector({
        zIndex: 3,
        source: this.polygonSource,
        style: new Style({
          fill: new Fill({
            color: this.form.body_color,
          }),
          stroke: new Stroke({
            width: 4,
            color: this.form.border_color,
          }),
        }),
      });
      this.map = new Map({
        // 创建地图
        target: "map",
        layers: [
          new LayerVector({
            zIndex: 3,
            // maxZoom: 11,
            source: this.hanShanSource,
            style: new Style({
              fill: new Fill({
                color: "rgba(255,255,255,.1)",
              }),
              stroke: new Stroke({
                width: 3,
                color: "#0f343d",
              }),
            }),
          }),
          // 星图地球底图层
          new TileLayer({
            zIndex: 1,
            preload: Infinity,
            source: new OSM({
              url: `https://tiles1.geovisearth.com/base/v1/img/{z}/{x}/{y}?secretId=${this.userInfo.hmac_sign.secretId}&clientId=${this.userInfo.hmac_sign.clientId}&expireTime=${this.userInfo.hmac_sign.expireTime}&sign=${this.userInfo.hmac_sign.sign}`,
            }),
          }),
          // 星图地球注记层
          new TileLayer({
            zIndex: 2,
            preload: Infinity,
            source: new OSM({
              url: `https://tiles1.geovisearth.com/base/v1/cat/{z}/{x}/{y}?format=webp&tmsIds=w&secretId=${this.userInfo.hmac_sign.secretId}&clientId=${this.userInfo.hmac_sign.clientId}&expireTime=${this.userInfo.hmac_sign.expireTime}&sign=${this.userInfo.hmac_sign.sign}`,
            }),
          }),
          this.vector,
        ],
        view: new View({
          projection: "EPSG:4326",
          center: [+this.lngNum, +this.latNum],
          zoom: 13,
          maxZoom: 20,
          constrainResolution: true,
          smoothResolutionConstraint: false,
          extent: [73.66, 3.86, 135.05, 53.55], //中国边界
        }),
        interactions: new defaults({
          doubleClickZoom: false,
        }),
      });
      if (this.scope_id) {
        this.getPlotInfoApi(this.scope_id);
      }
    },
    // 获取地块详情
    async getPlotInfoApi(scope_id) {
      const { code, results } = await getTownInfoApi({
        scope_id,
        manor_id: this.farmID,
      });
      if (code !== 0) return;
      let mark_data;
      let mark_data2;
      if (results[0].items[0].scope_body_color) {
        this.form.body_color = results[0].items[0].scope_body_color;
      }
      if (results[0].items[0].scope_border_color) {
        this.form.border_color = results[0].items[0].scope_border_color;
      }
      this.form.alpha = results[0].items[0].scope_alpha;
      if (typeof results[0].items[0].scope_mark_data == "string") {
        mark_data2 = JSON.parse(results[0].items[0].scope_mark_data)[0];
        if (mark_data2.name) {
          mark_data = mark_data2.coord;
        } else {
          mark_data = JSON.parse(results[0].items[0].scope_mark_data);
        }
      } else {
        mark_data = results[0].items[0].scope_mark_data;
      }
      this.pathsArr = mark_data || [];
      this.$forceUpdate();

      if (this.pathsArr.length) {
        this.showPolygon();
        this.startModify();
      }
    },
    // 添加地块
    async postAddPlotApi() {
      if (!this.pathsArr.length || !this.pathsArr) {
        this.$notify.error({
          title: "错误",
          message: "请绘制片区区域",
        });
        return;
      }
      let mark_data = JSON.stringify(this.pathsArr);
      const { code } = await postScopeMarkDataApi({
        scope_id: this.scope_id,
        mark_data,
        ...this.form,
      });
      if (code === 0) {
        this.$notify({
          title: "成功",
          message: "已添加",
          type: "success",
        });
        this.$router.go(-1);
      }
    },

    // 提交添加请求
    editMaps(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) return;
        this.postAddPlotApi();
      });
    },
    // 取消按钮
    hideMaps() {
      this.$router.go(-1);
    },

    // 添加标题开始
    createTitleStyle({ text }) {
      return new Style({
        text: new Text({
          font: "16px sans-serif",
          text,
          fill: new Fill({
            color: "#fff",
          }),
          backgroundFill: new Fill({
            color: "#032646",
          }),
          padding: [2, 2, 2, 2],
        }),
      });
    },

    // 绘制工具的选择
    changeType() {
      if (!this.form.body_color || !this.form.border_color) {
        this.$notify.error({
          title: "未选择填充颜色、边界颜色或透明度",
        });
        return;
      } else if (this.pathsArr.length) {
        return;
      } else {
        this.drawStatus = "绘制中...";
        this.drawPolygon();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.edit-map {
  height: 100vh;
  width: 558px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  border-right: 2px solid #071a2c;
  padding: 92px 55px 0 55px;
  background: url(../assets/image/land/editbgimg.jpg) center center no-repeat
    #0e2138;
  background-size: cover;
  color: #fff;
  .add-form {
    display: flex;
    flex-direction: column;
    width: 100%;

    .area {
      display: flex;
      align-items: center;

      .tips {
        color: #155ca5;
        font-size: 12px;
        margin-left: 11px;
      }
    }
    .slider-box {
      width: 100%;
      display: flex;
      align-items: center;
      color: #3e90e5;
      font-size: 12px;
    }
    /deep/.el-input__inner {
      background-color: #081c30;
      border: 1px solid #385576;
      color: #57708d;
      flex: 1;
    }
    /deep/.el-form-item__label {
      font-size: 14px;
      color: #fff;
      padding: 0 24px 0 0;
    }
    /deep/.el-select {
      &:nth-of-type(2) {
        margin-right: 0;
      }
    }
    /deep/.type .el-form-item__content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .select-box {
      width: 100%;
      display: flex;
    }
    .product-box {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding: 10px;
      .product-div {
        margin: 0 8px 0 0;
        .product-span {
          font-size: 14px;
          color: #fff;
          margin-right: 4px;
        }
        .el-icon-close {
          font-size: 16px;
          color: #409eff;
          cursor: pointer;
          &:hover {
            color: red;
          }
        }
      }
    }
    /deep/.type.hamlet-form-item {
      .el-form-item__content {
        flex-direction: row;
      }
    }
  }

  /deep/.el-select-dropdown {
    z-index: 10000 !important;
  }
  // 颜色拾取器
  /deep/.el-color-picker__trigger {
    padding: 0;
    margin-right: 30px;
  }
  // 透明度滑块
  /deep/.el-slider.el-slider--with-input {
    flex: 1;
    margin-left: 16px;
    .el-slider__runway.show-input {
      margin-right: 65px;
    }
    // 滑块-输入框
    .el-slider__input.el-input-number.el-input-number--small {
      width: 45px;
      margin-right: 7px;
      .el-input-number__increase,
      .el-input-number__decrease {
        display: none;
      }
      .el-input__inner {
        padding: 0;
        color: #3e90e5;
      }
    }
  }
  // 底部按钮
  /deep/.dialog-footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ok-button {
    width: 150px;
    height: 40px;
    margin-right: 54px;
  }

  .no-button {
    width: 150px;
    height: 40px;
    border: 1px solid #385576;
    background-color: #0f243d;
  }
}
#map {
  width: 100%;
  height: 100vh;
  position: relative;
  #tool-control,
  #tool-control2 {
    position: absolute;
    top: 80px;
    left: 0px;
    right: 0px;
    margin: auto;
    width: 252px;
    z-index: 2001;
    display: flex;
    align-items: center;
    .tool-item {
      width: 50px;
      height: 50px;
      padding: 5px;
      border-radius: 3px;
      background-color: #fff;
      border: 1px solid #fff;
      color: #666;
      font-size: 14px;
      cursor: pointer;
      line-height: 20px;
      text-align: center;
      &:hover {
        border-color: #789cff;
      }
    }
  }
  #tool-control2 {
    left: 160px;
  }
}
</style>
